import React from "react";
import StepZilla from "react-stepzilla";
import SelectFile from "./SelectFile";
import SelectAuthor from "./SelectAuthor";
import ReplaceAuthor from "./ReplaceAuthor";
import Completed from "./Completed";
import "react-stepzilla/src/css/main.css";

function Stepper(props) {
  const {
    file,
    setFile,
    selected,
    list,
    setSelected,
    setAuthor,
    author,
    handleClick,
  } = props;
  const steps = [
    {
      name: "1. Select Document",
      component: <SelectFile setFile={setFile} file={file} />,
    },
    {
      name: "2. Select Authors",
      component: (
        <SelectAuthor
          list={list}
          selected={selected}
          setSelected={setSelected}
        />
      ),
    },
    {
      name: "3. Enter new Author Name",
      component: (
        <ReplaceAuthor
          file={file}
          selected={selected}
          setAuthor={setAuthor}
          author={author}
          handleClick={handleClick}
        />
      ),
    },
    {
      name: "4. Done",
      component: <Completed />,
    },
  ];
  return (
    <div>
      <StepZilla
        steps={steps}
        nextTextOnFinalActionStep="Anonymize selected authors"
        backButtonCls="btn btn-next btn-primary btn-md pull-left"
        nextButtonCls="btn btn-next btn-primary btn-md pull-right"
        stepsNavigation={false}
        prevBtnOnLastStep={false}
        backButtonText="Back"
        onStepChange={(step) => {
          if (step === 3) {
            handleClick();
          }
        }}
      />
    </div>
  );
}

export default Stepper;
