import React from "react";
import Card from "react-bootstrap/Card";

function Completed({ jumpToStep }) {
  return (
    <Card>
      <Card.Header className="">Save your new document</Card.Header>
      <Card.Body>
      The document has been successfully anonymized. Depending on your browser settings, it has either been saved to your Downloads folder, or you can manually save it now.<br/><br/>{" "}
        <span
          className="link-primary"
          role="button"
          onClick={() => jumpToStep(0)}
        >
          Anonymize another document.
        </span>
      </Card.Body>
    </Card>
  );
}

export default Completed;
