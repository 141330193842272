import React, { useImperativeHandle, forwardRef, useState } from "react";
import AuthorList from "./AuthorList";
import Card from "react-bootstrap/Card";

const SelectAuthor = forwardRef(({ list, selected, setSelected }, ref) => {
  const [error, setError] = useState(false);

  useImperativeHandle(ref, () => ({
    isValidated() {
      if (selected.length === 0) {
        setError(true);
        return false;
      } else {
        setError(false);
        return true;
      }
    },
  }));

  return (
    <>
      <Card>
        <Card.Header className="">
        Select the authors you would like to anonymize
</Card.Header>
        <Card.Body>
          <AuthorList
            data={list}
            selected={selected}
            setSelected={setSelected}
          />
        </Card.Body>
      </Card>
      {error && (
        <div className="alert alert-danger" role="alert">
         Select the authors you would like to anonymize.
        </div>
      )}
    </>
  );
});

export default SelectAuthor;
