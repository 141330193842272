import React, {
  useCallback,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import Card from "react-bootstrap/Card";
import { useDropzone } from "react-dropzone";
const SelectFile = forwardRef(({ setFile, file, jumpToStep }, ref) => {
  const [error, setError] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const onDropAccepted = useCallback(() => {
    setError("");
    jumpToStep(1);
  }, []);
  const onDropRejected = useCallback(
    (error) => setError("Please provide a Word document"),
    []
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    onDrop,
    onDropAccepted,
    onDropRejected,
    maxFiles: 1,
  });
  useImperativeHandle(ref, () => ({
    isValidated() {
      if (!file) {
        setError("Please select a file");
        return false;
      } else {
        setError("");
        return true;
      }
    },
  }));
  return (
    <>
      <Card>
        <Card.Header className="">Select your document</Card.Header>
        <Card.Body {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()}></input>
          <p className="mt-3 px-2">
            {file ? file.name : "Select your .docx file to start anonymizing author names of track changes and comments. Simply drag and drop the file here, or click here to browse and select it."}
          </p>
        </Card.Body>
      </Card>
      {error && (
        <div className="alert alert-danger mt-2" role="alert">
          {error}
        </div>
      )}
    </>
  );
});

export default SelectFile;
